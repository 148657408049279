.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  .body {
    overflow: auto;
    padding-bottom: 12px;
    flex: 1;
  }
  .footer {
    max-height: 150px;
    // padding-bottom: 4px;
    .buttons {
      padding: 12px 0px 8px 0px;
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
    }
  }
}
.header {
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  align-items: center;
  height: 34px;
}

.chat {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .copycontainer {
    display: flex;
    align-items: center;
    background-color: #eee;
  }

  .copyicon {
    width: 14px;
    height: 22px;
    margin-bottom: 0;
  }
  .markdown {
    flex: 1;
    margin-left: 4px;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 4px;
  }
}
