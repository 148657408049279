.root {
  height: 100vh;
  overflow: hidden;
}
.header {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #ddd;
  padding: 12px;
  box-sizing: border-box;
  .headeravatar {
    margin-left: auto;
    cursor: pointer;
  }
}
.body {
  height: 100%;
  // height: calc(100% - 40px);
  // overflow: auto;
  display: flex;
}
// .preview {
//   flex: 1;
//   border-right: 1px solid #ccc;
//   padding: 8px;
// }
.edit {
  flex: 2;
  border-right: 1px solid #ddd;
  padding: 12px;
  overflow: auto;
}
.editcontainer {
  // height: calc(100% - 98px);
  overflow: auto;
}

.copilit {
  flex: 2;
  padding: 12px;
}
